import axios from "axios";
import config from "@config";
import { getCurrentLanguage, i18n } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";
import store from "@/store";
import graphql from "@graphql";

/*** 
 * 
 * merge guest wishlist to the logged in user
 * 
 */

export async function mergeGuestWishlist(guestWishlistId, userWishlistId) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.getters["user/getUserToken"],
    Store: storeview,
  };

  const query = "mutation {mergeWishlists (source_wishlist_id: $sourceWishlistId, destination_wishlist_id: $destinationWishlistId) { items { product { name meta_title } } items_count id } }";

  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query.replace('$sourceWishlistId', '"' + guestWishlistId + '"').replace('$destinationWishlistId', '"' + userWishlistId + '"') },
  }).catch((e) => {
    Logger.error("mergeGuestWishlist", "data-resolver wishlist", e)();
    throw e;
  });
  if (retval.data.data.mergeGuestWishlist != null) {
    return retval.data.data.mergeGuestWishlist;
  } else {
    return false;
  }
}

/**
 * Login as customer
 *
 * @param {string} username
 * @param {string} password
 * @returns token or false
 */
export async function login(username, password) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query =
    'mutation { generateCustomerToken(email: ' +
    JSON.stringify(username) +
    ', password: ' +
    JSON.stringify(password) +
    ') { token }}';

  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("login", "data-resolver user", e)();
    throw e;
  });

  if (retval.data.errors != null) {
    retval.data.errors.forEach((element) => {
      const msg = {
        type: "danger",
        title: "login_error",
        text: element.message,
      };
      store.dispatch("messages/sendMessage", { message: msg });
    });
  }
  if (retval.data.data.generateCustomerToken.token != null) {
    return retval.data.data.generateCustomerToken.token;
  } else {
    return false;
  }
}

/**
 * create customer account
 *
 * @param {object} account
 * @returns true or false
 */
export async function createAccount(account) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  if (typeof account.is_subscribed == "undefined") {
    account.is_subscribed = false;
  }

  let query = "mutation { createCustomerWithAddress( input: {";
  if ("firstname" in account) {
    query = query + ' firstname: ' + JSON.stringify(account.firstname);
  }
  if ("middlename" in account) {
    query = query + ' middlename: ' + JSON.stringify(account.middlename);
  }
  if ("lastname" in account) {
    query = query + ' lastname: ' + JSON.stringify(account.lastname);
  }

  if ("email" in account) {
    query = query + ' email: ' + JSON.stringify(account.email);
  }
  if ("password" in account) {
    query = query + ' password: ' + JSON.stringify(account.password);
  }
  if ("is_subscribed" in account) {
    query = query + " is_subscribed: " + account.is_subscribed;
  }
  if ("gender" in account) {
    query = query + " gender: " + account.gender;
  }

  if ("date_of_birth" in account) {
    query = query + ' date_of_birth: ' + JSON.stringify(account.date_of_birth);
  }
  if ("taxvat" in account) {
    query = query + ' taxvat: "' + account.taxvat.toUpperCase() + '" ';
  }
  query = query + " address: {";

  if ("country_code" in account.address) {
    query = query + " country_code: " + account.address.country_code;
  }
  if ("street" in account.address) {
    query = query + ' street: ' + JSON.stringify(account.address.street);
  }
  if ("postcode" in account.address) {
    query = query + ' postcode: ' + JSON.stringify(account.address.postcode);
  }
  if ("city" in account.address) {
    query = query + ' city: ' + JSON.stringify(account.address.city);
  }
  if ("telephone" in account.address) {
    query = query + ' telephone: ' + JSON.stringify(account.address.telephone);
  }
  if ("firstname" in account) {
    query = query + ' firstname: ' + JSON.stringify(account.firstname);
  }
  if ("middlename" in account) {
    query = query + ' middlename: ' + JSON.stringify(account.middlename);
  }
  if ("lastname" in account) {
    query = query + ' lastname: ' + JSON.stringify(account.lastname);
  }
  if ("taxvat" in account) {
    query = query + ' vat_id: "' + account.taxvat.toUpperCase() + '" ';
  }
  if ("coc_number" in account) {
    query = query + ' coc: ' + JSON.stringify(account.coc_number);
  }
  if ("default_shipping" in account.address) {
    query = query + " default_shipping: " + account.address.default_shipping;
  }
  if ("default_billing" in account.address) {
    query = query + " default_billing: " + account.address.default_billing;
  }
  if ("company" in account) {
    query = query + ' company: ' + JSON.stringify(account.company);
  }
  if ("vat_id" in account) {
    query = query + ' vat_id: "' + account.taxvat.toUpperCase() + '" ';
  }
  if ("prefix" in account) {
    query = query + ' prefix: ' + JSON.stringify(account.prefix);
  }
  if ("suffix" in account) {
    query = query + ' suffix: ' + JSON.stringify(account.suffix);
  }
  query = query + "}";
  query =
    query + " } ) { token customer { firstname lastname email is_subscribed } } }";
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("createAccount", "data-resolver user", e)();
    throw e;
  });
  if (retval.data.errors != null) {
    retval.data.errors.forEach((element) => {
      const msg = {
        type: "danger",
        title: "register_error",
        text: element.message,
      };
      store.dispatch("messages/sendMessage", { message: msg });
    });
  }
  if (retval.data.data.createCustomerWithAddress != null) {
    return retval.data.data.createCustomerWithAddress.token;
  } else {
    return false;
  }
}

/**
 * update customer account
 *
 * @param {object} account
 * @returns true or false
 */
export async function updateAccount(account) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.getters["user/getUserToken"],
    Store: storeview,
  };

  let query = "mutation { updateCustomer( input: {";
  if ("firstname" in account) {
    query = query + ' firstname: ' + JSON.stringify(account.firstname);
  }
  if ("middlename" in account) {
    query = query + ' middlename: ' + JSON.stringify(account.middlename);
  }
  if ("lastname" in account) {
    query = query + ' lastname: ' + JSON.stringify(account.lastname);
  }
  if ("prefix" in account) {
    query = query + ' prefix: ' + JSON.stringify(account.prefix);
  }
  if ("suffix" in account) {
    query = query + ' suffix: ' + JSON.stringify(account.suffix);
  }

  if ("email" in account) {
    query = query + ' email: ' + JSON.stringify(account.email);
  }
  if ("password" in account) {
    query = query + ' password: ' + JSON.stringify(account.password);
  }
  if ("is_subscribed" in account) {
    query = query + " is_subscribed: " + account.is_subscribed;
  }
  if ("gender" in account) {
    query = query + " gender: " + account.gender;
  }

  if ("date_of_birth" in account) {
    query = query + ' date_of_birth: ' + account.date_of_birth;
  }
  if ("taxvat" in account) {
    query = query + ' taxvat: "' + account.taxvat.toUpperCase() + '" ';
  }

  query =
    query + " } ) { customer { firstname lastname email is_subscribed } } }";
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("updateAccount", "data-resolver user", e)();
    throw e;
  });
  if (retval.data.data.updateCustomer != null) {
    return true;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
      });
      return retval.data.errors;
    }
    return false;
  }
}


/**
 *  
 * wishlist for not logged in 
 * 
 */
export async function createGuestWishlist() {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    //Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query = " mutation {createEmptyWishlist}";
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("createGuestWishlist", "data-resolver wishlist", e)();
    throw e;
  });
  if (retval.data.data.createEmptyWishlist != null) {
    return retval.data.data.createEmptyWishlist;
  } else {
    return false;
  }
}


/**
 * add product to the wishlist
 * @param {string} sku
 * @param {string} parentSku
 * @returns true or false
 */
export async function addProductToWishlist(sku, parentSku) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.getters["user/getUserToken"],
    Store: storeview,
  };

  let query = "mutation { addProductsToWishlist( ";
  query = query + 'wishlistId: "' + store.getters["user/getWishlistId"] + '"';
  query = query + ' wishlistItems: [{ sku: "' + sku + '"';
  if (parentSku != null) {
    query = query + ' parent_sku: "' + parentSku + '"';
  }

  query = query + " quantity: 1}]";

  query =
    query +
    " ) {user_errors { code message } wishlist { id items_count items { id qty product { url_key name sku id image { url } price_range { minimum_price { final_price { currency value } regular_price { currency value } } maximum_price { regular_price { currency value } } } } } } } }";
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("addProductToWishlist", "data-resolver user", e)();
    throw e;
  });
  if (retval.data.data.addProductsToWishlist != null) {
    if (retval.data.data.addProductsToWishlist.user_errors.length > 0) {
      const msg = {
        type: "danger",
        title: "Wensenlijst",
        text: retval.data.data.addProductsToWishlist.user_errors[0].message,
      };
      store.dispatch("messages/sendMessage", { message: msg });
    }

    return retval;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
      });
    }
    return false;
  }
}

/**
 * remove product from wishlist
 * @param {integer} id
 * @returns true or false
 */
export async function removeProductFromWishlist(id) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.getters["user/getUserToken"],
    Store: storeview,
  };

  let query = "mutation { removeProductsFromWishlist( ";
  query = query + 'wishlistId: "' + store.getters["user/getWishlistId"] + '"';
  query = query + ", wishlistItemsIds: [" + id + "]";

  query =
    query +
    " ) {user_errors { code message } wishlist { id items_count items { id qty product { url_key name sku id image { url } price_range { minimum_price { final_price { currency value } regular_price { currency value } } maximum_price { regular_price { currency value } } } } } } } }";
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("removeProductFromWishlist", "data-resolver user", e)();
    throw e;
  });
  if (retval.data.data.removeProductsFromWishlist != null) {
    return retval;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
      });
    }
    return false;
  }
}

/**
 * get current user
 * @returns user object
 */
export async function getCurrent() {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.getters["user/getUserToken"],
    Store: storeview,
  };

  const query = graphql.queryFields.user;

  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("getCurrent", "data-resolver user", e)();
    throw e;
  });
  if (retval.data.data.customer != null) {
    return retval.data.data.customer;
  } else {
    return false;
  }
}

/**
 * add address to customer
 * @param {object} address
 * @returns true or false
 */
export async function createAddress(address) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.getters["user/getUserToken"],
    Store: storeview,
  };
  let query = "mutation { createCustomerAddress(input: { ";
  if ("country_code" in address) {
    query = query + " country_code: " + address.country_code + " ";
  }
  if ("street" in address) {
    query = query + "street: " + JSON.stringify(address.street) + " ";
  }
  if ("postcode" in address) {
    query = query + 'postcode: ' + JSON.stringify(address.postcode);
  }
  if ("city" in address) {
    query = query + 'city: ' + JSON.stringify(address.city);
  }
  if ("telephone" in address) {
    query = query + 'telephone: ' + JSON.stringify(address.telephone);
  }
  if ("firstname" in address) {
    query = query + 'firstname: ' + JSON.stringify(address.firstname);
  }
  if ("middlename" in address) {
    query = query + 'middlename: ' + JSON.stringify(address.middlename);
  }
  if ("lastname" in address) {
    query = query + 'lastname: ' + JSON.stringify(address.lastname);
  }
  if ("default_shipping" in address) {
    query = query + "default_shipping: " + address.default_shipping + " ";
  }
  if ("default_billing" in address) {
    query = query + "default_billing: " + address.default_billing + " ";
  }
  if ("company" in address) {
    query = query + ' company: ' + JSON.stringify(address.company);
  }
  if ("vat_id" in address) {
    query = query + ' vat_id: "' + address.vat_id.toUpperCase() + '" ';
  }
  if ("coc" in address) {
    query = query + ' coc: "' + address.coc + '" ';
  }
  if ("prefix" in address) {
    query = query + ' prefix: ' + JSON.stringify(address.prefix);
  }
  if ("suffix" in address) {
    query = query + ' suffix: ' + JSON.stringify(address.suffix);
  }
  query =
    query +
    " }) { id region { region region_code } country_code street telephone postcode city default_shipping default_billing }}";
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("createAddress", "data-resolver user", e)();
    throw e;
  });
  if (retval.data.data.createCustomerAddress != null) {
    return true;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
      });
    }
    return false;
  }
}

/**
 * update address of customer
 * @param {integer} id
 * @param {object} address
 * @returns true or false
 */
export async function updateAddress(id, address) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.getters["user/getUserToken"],
    Store: storeview,
  };
  let query = "mutation { updateCustomerAddress(id:" + id + ", input: { ";
  if ("country_code" in address) {
    query = query + " country_code: " + address.country_code + " ";
  }
  if ("street" in address) {
    query = query + "street: " + JSON.stringify(address.street) + " ";
  }
  if ("postcode" in address) {
    query = query + ' postcode: ' + JSON.stringify(address.postcode);
  }
  if ("city" in address) {
    query = query + ' city: ' + JSON.stringify(address.city);
  }
  if ("telephone" in address) {
    query = query + ' telephone: ' + JSON.stringify(address.telephone);
  }
  if ("firstname" in address) {
    query = query + ' firstname: ' + JSON.stringify(address.firstname);
  }
  if ("middlename" in address) {
    query = query + ' middlename: ' + JSON.stringify(address.middlename);
  }
  if ("lastname" in address) {
    query = query + ' lastname: ' + JSON.stringify(address.lastname);
  }
  if ("default_shipping" in address) {
    query = query + "default_shipping: " + address.default_shipping + " ";
  }
  if ("default_billing" in address) {
    query = query + "default_billing: " + address.default_billing + " ";
  }
  if ("company" in address) {
    query = query + ' company: ' + JSON.stringify(address.company);
  }
  if ("vat_id" in address) {
    query = query + ' vat_id: "' + address.vat_id.toUpperCase() + '" ';
  }
  if ("coc" in address) {
    query = query + ' coc: "' + address.coc + '" ';
  }
  if ("prefix" in address) {
    query = query + ' prefix: ' + JSON.stringify(address.prefix);
  }
  if ("suffix" in address) {
    query = query + ' suffix: ' + JSON.stringify(address.suffix);
  }

  query =
    query +
    " }) { id firstname middlename lastname company prefix suffix region { region region_code } country_code street telephone postcode city default_shipping default_billing }}";
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("updateAddress", "data-resolver user", e)();
    throw e;
  });
  if (retval.data.data.updateCustomerAddress != null) {
    return true;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
      });
    }
    return false;
  }
}

/**
 * change password
 *
 * @param {string} currentPassword
 * @param {string} newPassword
 * @returns true or false
 */
export async function changePassword(currentPassword, newPassword) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.getters["user/getUserToken"],
    Store: storeview,
  };
  const query =
    'mutation { changeCustomerPassword(currentPassword: ' +
    JSON.stringify(currentPassword) +
    ' newPassword: ' +
    JSON.stringify(newPassword) +
    '){id email} }';
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("changePassword", "data-resolver user", e)();
    throw e;
  });
  if (retval.data.data.changeCustomerPassword != null) {
    return true;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
      });
    }
    return false;
  }
}

/**
 * delete address from customer
 *
 * @param {integer} id
 * @returns true or false
 */
export async function deleteAddress(id) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.getters["user/getUserToken"],
    Store: storeview,
  };
  const query = "mutation { deleteCustomerAddress(id: " + id + ") }";
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("deleteAddress", "data-resolver user", e)();
    throw e;
  });
  if (retval.data.data.deleteCustomerAddress != null) {
    return retval.data.data.deleteCustomerAddress;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
        const msg = {
          type: "danger",
          text: element.message,
        };
        store.dispatch("messages/sendMessage", { message: msg });
      });
    }
    return false;
  }
}

/**
 * newsletter signup
 *
 * @param {string} email
 * @returns email address or false
 */
export async function newsletterSignup(email) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  let headers = {};
  if (store.getters["user/getIsLoggedIn"] != false) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["user/getUserToken"],
      Store: storeview,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + config.shop.consumerKey,
      Store: storeview,
    };
  }

  // const query =
  //   'mutation { addNewsletterSubscriber(input: {email: "' +
  //   email +
  //   '"}) {email}}';

  const query =
    'mutation { subscribeEmailToNewsletter( email: ' +
    JSON.stringify(email) +
    ' ) { status } }';

  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("newsletterSignup", "data-resolver user", e)();
    throw e;
  });
  if (retval.data.errors != null) {
    retval.data.errors.forEach((element) => {
      if (store.getters["user/getIsLoggedIn"] != false) {
        if (element.extensions.category === "graphql-authorization") {
          return store.dispatch("user/sessionExpired");
        }
      }
      const msg = {
        type: "danger",
        title: i18n.t("newsletter_error"),
        text: element.message,
      };
      store.dispatch("messages/sendMessage", { message: msg });
    });
  }
  if (retval.data.data.subscribeEmailToNewsletter != null) {
    return retval.data.data.subscribeEmailToNewsletter.status;
  } else {
    return false;
  }
}

/**
 * get countries
 *
 * @returns array of countries
 */
export async function getCountries() {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query =
    "query { countries { id two_letter_abbreviation three_letter_abbreviation full_name_locale full_name_english available_regions { id code name } } }";
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("getCountries", "data-resolver user", e)();
    throw e;
  });
  if (retval.data.data.countries != null) {
    return retval.data.data.countries;
  } else {
    return false;
  }
}

/**
 * validate dutch postcode
 *
 * @param {string} postcode
 * @param {integer} number
 * @returns short address object or false
 */
export async function validatePostcode(postcode, number) {
  const headers = {
    "Content-Type": "application/json",
    "Client-id": config.postcode_validation.client_id,
    Secret: config.postcode_validation.client_secret,
  };

  const retval = await axios({
    url:
      config.postcode_validation.url +
      "?postcode=" +
      encodeURIComponent(postcode) +
      "&number=" +
      encodeURIComponent(number),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("validatePostcode", "data-resolver user", e)();
    return false;
  });
  if (retval.data != null) {
    return retval.data;
  } else {
    return false;
  }
}

/**
 *
 * @param {string} vat
 * @returns true or false
 */
export async function validateVatNumber(vat) {
  const headers = {
    "Content-Type": "application/json",
    "Client-id": config.vat_validation.client_id,
    Secret: config.vat_validation.client_secret,
  };

  vat = vat.toUpperCase();
  let formatBe = /^BE\d{10}$/;
  let formatNl = /^NL\d{9}B\d{2}$/;
  let formatDe = /^DE\d{9}$/;

  var formatValid = false;
  var country_code = '';
  switch (vat.slice(0, 2)) {
    case 'NL':
      formatValid = formatNl.test(vat);
      country_code = 'NL';
      break;
    case 'DE':
      formatValid = formatDe.test(vat);
      country_code = 'DE';
      break;
    case 'BE':
      formatValid = formatBe.test(vat);
      country_code = 'BE';
      break;
    default:
      formatValid = /^[A-Z]{2}.{8,12}$/.test(vat); //A valid VAT number is between 8-12 characters long excluding country code
      country_code = vat.slice(0, 2);
  }

  if (!formatValid) {
    return false;
  }
  const retval = await axios({
    url: config.vat_validation.url + "?country_code=" + country_code + "&vat_id=" + vat + "&requester_country_code=" + config.vat_validation.requester_country_code + "&requester_vat_id=" + config.vat_validation.requester_vat_id,
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("validateVatNumber", "data-resolver user", e)();
    throw e;
  });
  if (retval.data != null) {
    return retval.data;
  } else {
    return false;
  }
}

/**
 * check if email is available
 *
 * @param {string} email
 * @returns true or false
 */
export async function checkEmailExist(email) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query =
    'query { isEmailAvailable(email: "' +
    email +
    '"){is_email_available} }';
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("checkEmailExist", "data-resolver user", e)();
    throw e;
  });
  if (retval.data.data.isEmailAvailable != null) {
    return retval.data.data.isEmailAvailable.is_email_available;
  } else {
    return false;
  }
}

/**
 *
 * @returns get all past orders of customer
 */
export async function getMyOrders({ page, perPage, search }) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.getters["user/getUserToken"],
    Store: storeview,
  };

  const query = graphql.queryFields.myOrders.replace('$currentPage', page).replace('$pageSize', perPage).replace('$searchTxt', search ? 'match: "' + search + '"' : '');

  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("customerOrders", "data-resolver user", e)();
    throw e;
  });
  if (retval.data.data.customer.orders != null) {
    return retval.data.data.customer.orders;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
      });
    }
    return false;
  }
}

/**
 * forgot password function
 *
 * @param {string} email
 * @returns object
 */
export async function forgotPassword(email) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query = 'mutation { requestPasswordResetEmail(email: ' + JSON.stringify(email) + ')}';
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("forgotPassword", "data-resolver user", e)();
    throw e;
  });
  if (retval.data.errors != null) {
    retval.data.errors.forEach((element) => {
      const msg = {
        type: "danger",
        title: i18n.t("password_reset_error"),
        text: i18n.t(element.message),
      };
      store.dispatch("messages/sendMessage", { message: msg });
    });
  }
  return retval.data.data.requestPasswordResetEmail;
}

/**
 * reset password
 *
 * @param {string} email
 * @param {string} resetPasswordToken
 * @param {string} newPassword
 * @returns true or false
 */
export async function resetPassword(email, resetPasswordToken, newPassword) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query =
    'mutation { resetPassword(email: ' +
    JSON.stringify(email) +
    ' resetPasswordToken: ' +
    JSON.stringify(resetPasswordToken) +
    ' newPassword: ' +
    JSON.stringify(newPassword) +
    ' )}';
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("forgotPassword", "data-resolver user", e)();
    throw e;
  });
  /*
  if (retval.data.errors != null) {
    retval.data.errors.forEach((element) => {
      const msg = {
        type: "danger",
        title: "user",
        text: element.message,
      };
      //store.dispatch("messages/sendMessage", { message: msg });
    });
  }
  */
  if (retval.data.data.resetPassword == null) {
    return false;
  } else {
    return true;
  }
}

/**
 * add description to a wishlist item
 */

export async function addWishlistItemDescription({ wishItemId, description, qty }) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.getters["user/getUserToken"],
    Store: storeview,
  };

  let query = "mutation { updateProductsInWishlist( ";
  query = query + 'wishlistId: "' + store.getters["user/getWishlistId"] + '"';
  query =
    query +
    " wishlistItems: [{ wishlist_item_id: " +
    wishItemId +
    ' description: "' +
    description +
    '" quantity: ' + qty + '}]';

  query =
    query +
    " ) {user_errors { code message } wishlist { id items_count items { id qty product { url_key name sku id price_range { minimum_price { regular_price { currency value } } maximum_price { regular_price { currency value } } } } } } } }";

  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("updateWishItemDesc", "AccountWishlist.vue", e)();
    throw e;
  });

  if (retval.data.data.updateProductsInWishlist != null) {
    return true;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        const msg = {
          type: "danger",
          title: "",
          text: element.message,
        };
        store.dispatch("messages/sendMessage", { message: msg });
      });
    }
    return false;
  }
}


/**
 * share wishlist
 */

export async function handleShareWishlist({ name, emails, message }) {

  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.getters["user/getUserToken"],
    Store: storeview,
  };

  let query = "mutation { shareWishlist( input: {";
  query += " wishlist_id: " + store.getters["user/getWishlistId"];
  query += ' customer_name: "' + name + '" recipients: [';

  for (const email of emails) {
    query += ' { email: "' + email + '" }';
  }

  query += ' ] message: "' + message + '"';
  query += " } ) { errors { message } wishlist_id recipients { email } } }";

  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("updateWishItemDesc", "AccountWishlist.vue", e)();
    throw e;
  });

  if (retval.data.data.shareWishlist != null) {
    return retval.data.data.shareWishlist;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        const msg = {
          type: "danger",
          title: "",
          text: element.message,
        };
        store.dispatch("messages/sendMessage", { message: msg });
      });
    }
    return false;
  }
}

/** 
 * get shared wishlist
 */

export async function getSharedWishlistBySharingCode(sharing_code) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query =
    '{ sharedWishlist(code: "' +
    sharing_code +
    '") { items_v2 { page_info { current_page page_size total_pages } ' +
    graphql.queryFields.wishlist +
    " } items_count } }";

  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURI(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("getSharedWishlist", "account.js", e)();
    throw e;
  });

  return retval.data.data.sharedWishlist;
}
/**
 * create customer account
 *
 * @param { object } account
 * @returns string|boolean
 */
export async function createAccountFromOrder(account) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  let query = "mutation {  createAccountFromOrder(input: { order_id: " + account.order_id + ' email:"' + account.email + '" password:"' + account.password + '"';
  query =
    query + " } ) { token customer { firstname lastname email is_subscribed } } }";
  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("createAccount", "data-resolver user", e)();
    throw e;
  });
  if (retval.data.errors != null) {
    retval.data.errors.forEach((element) => {
      const msg = {
        type: "danger",
        title: "register_error",
        text: element.message,
      };
      store.dispatch("messages/sendMessage", { message: msg });
    });
  }
  if (retval.data.data.createAccountFromOrder != null) {
    return retval.data.data.createAccountFromOrder.token;
  } else {
    return false;
  }
}

export async function getReviews() {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.getters["user/getUserToken"],
    Store: storeview,
  };

  const query = graphql.queryFields.customerReviews;

  const retval = await axios({
    url: config.shop.graphQLURL,
    method: "POST",
    headers: headers,
    data: { query: query },
  }).catch((e) => {
    Logger.error("customerReviews", "data-resolver user", e)();
    throw e;
  });
  // console.log("retval.data",retval.data)
  if (retval.data.data.customer.reviews != null) {
    return retval.data.data.customer.reviews;
  } else {
    if (retval.data.errors != null) {
      retval.data.errors.forEach((element) => {
        if (store.getters["user/getIsLoggedIn"] != false) {
          if (element.extensions.category === "graphql-authorization") {
            return store.dispatch("user/sessionExpired");
          }
        }
      });
    }
    return false;
  }
}
