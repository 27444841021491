import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/router";
//import { VueRouterEx } from "@storefront/core/lib/router";
import { beforeEachGuard } from "@storefront/core/router/beforeEach.js";
import { getCurrentLanguage } from "../i18n";
import { cleanPath } from "./helpers/cleanPath";
//import {Logger} from "@storefront/core/lib/logger";
import config from "@config";
import store from "@/store";

Vue.use(VueRouter);


export default () => {
  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes,
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
  });

  router.beforeEach(async (to, from, next) => {

    store.dispatch('setLoading', true)

    let toRoute = to
    if (getCurrentLanguage() != config.languages.default && to.path.split('/')[1] != getCurrentLanguage()) { //  
      toRoute = {
        ...toRoute,
        path: '/' + getCurrentLanguage() + (to.path === '/' ? '' : to.path),
        fullPath: '/' + getCurrentLanguage() + (to.fullPath === '/' ? '' : to.fullPath)
      }
    }
    if ((toRoute.name == null  && from.name == "page-not-found") || (from.name == "login" && toRoute.name == "account") || (from.name == "account" && toRoute.name == "login") || (from.name == "create-account" && toRoute.name == "account")|| (from.name == "account" && toRoute.name == "create-account")) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        store.dispatch('setLoading', false);
      }, 500);
    }
    return await beforeEachGuard(toRoute, from, next, router);

  });

  router.afterEach(async (to, from) => {

    if (cleanPath(from) != cleanPath(to) && to.path.split('/')[1] != getCurrentLanguage() && getCurrentLanguage() != config.languages.default) {
      router.push('/' + getCurrentLanguage() + to.fullPath)
    }

    setTimeout(() => {
      store.dispatch('setLoading', false);
    }, 1000);
  })

  return router;
};
