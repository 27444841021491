import { Logger } from "@storefront/core/lib/logger";
import { loadCategoryMenu } from "@storefront/core/data-resolver/categories";
import config from "@config";
const state = () => ({
  menu: [],
});

const actions = {
  /**
   * load Blog by Identifier
   * @param {json} identifier
   */
  async loadMenu({ commit }) {
    const page = await loadCategoryMenu(config.categories.menu).catch((e) => {
      Logger.error("load", "menu store actions", e)();
      throw e;
    });
    
    if (page != null) {
      commit("setMenu", []);
      page.children.forEach((element) => {
        if (element.include_in_menu == 1) {
          commit("setMenuItem", element);
        }
      });
      //commit("setMenu", page.children);
    }
  },
};

const mutations = {
  /**
   * set list menu
   * @param {object} state
   * @param {object} payload
   */
  setMenu(state, payload) {
    state.menu = payload;
  },
  /**
   * set list menu
   * @param {object} state
   * @param {object} payload
   */
  setMenuItem(state, payload) {
    state.menu.push(payload);
  },
};

const getters = {
  getMenu: (state) => state.menu,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
